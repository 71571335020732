<template>
    <div class="inner-wrapper" v-if="data && data.templateContentFields">
        <div class="thumb" v-if="data.templateContentMedia &&
            data.templateContentMedia
                .mediaFirstImage &&
            data.templateContentMedia
                .mediaFirstImage !== ''
            ">
            <img :alt="data.templateContentMedia.mediaFirstImage.title"
                :src="data.templateContentMedia.mediaFirstImage.url" />
        </div>
        <div class="content-wrapper">
            <h2 class="column-title" v-if="data.templateContentFields.displayTitle !== '#'">
                {{ data.templateContentFields.displayTitle }}
            </h2>
            <div class="text-wrapper" v-if="data.templateContentFields
                .contentFirst &&
                data.templateContentFields
                    .contentFirst !== ''
                " v-html="parseHtml(data.templateContentFields.contentFirst)">
            </div>

            <nav class="content-nav"
                v-if="data.templateContentFields.overviewButtonUrl && data.templateContent.templateContentFields.overviewButtonUrl !== ''">
                <ul class="buttons-wrapper">
                    <li>
                        <router-link class="button" :to="data.templateContentFields.overviewButtonUrl">{{
                            data.templateContent.templateContentFields.overviewButtonLabel }}</router-link>
                    </li>
                </ul>
            </nav>

        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            componentClass: ''
        };
    },
    components: {

    },
    props: ['data'],
    inject: ["isAuthenticated"],
    methods: {
        parseHtml(passedHtml) {
            return passedHtml.replaceAll(
                'rel="noopener noreferrer" target="_blank"',
                ""
            );
        },
    }

};
</script>